"./prjectImages";
const countrydetails1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340204/countrydetails1_ji5mf2.jpg";
const countrydetails2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340211/countrydetails2_wu6bck.jpg";
const prankcalculator1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340271/prankcalculator1_xawupa.jpg";
const prankcalculator2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340276/prankcalculator2_ilvaah.jpg";
const downsyndrome1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340217/downsyndrome1_tw2v2v.jpg";
const downsyndrome2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340221/downsyndrome2_ad9rx4.jpg";
const oasis1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340260/oasis1_bdt4pw.jpg";
const oasis2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340264/oasis2_ct08zl.jpg";
const movie1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340240/moviefront_acbiml.png";
const movie2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340245/moviesecond_nioycf.png";
const nottodo1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340249/nottodofirst_dysxmp.png";
const nottodo2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340255/nottodosecond_gbdlbd.png";
const expenses1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340226/expensesfirst_yjylba.png";
const expenses2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340235/expensessecond_lf79gz.png";
const Ecomcms1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340192/1_ajmaco.png";
const Ecomcms2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340196/2_pflmnb.png";
const Ecomclient1 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340172/1_bzv6st.png";
const Ecomclient2 =
  "https://res.cloudinary.com/dt5eiyxyc/image/upload/v1689340184/2_k30a1v.png";

export const projectsData = [
  {
    id: 1,
    image: Ecomcms1,
    transitionImage: Ecomcms2,
    title: "PK-ECOM CMS || MERN",
    codeLink: "https://github.com/orgs/PK-ECOM-CMS/repositories",
    liveLink: "https://pk-ecom-cms.vercel.app/",
    content: "It is an admin CMS for an E-Commerce Platform I am developing.",
  },
  {
    id: 1,
    image: Ecomclient1,
    transitionImage: Ecomclient2,
    title: "PK-ECOM || MERN",
    codeLink: "https://github.com/orgs/PK-ECOM-CLIENT/repositories",
    liveLink: "https://pk-ecom.vercel.app/",
    content: "It is the client side E-Commerce application.",
  },
  {
    id: 2,
    image: expenses1,
    transitionImage: expenses2,
    title: "Expenses Tracker || MERN",
    codeLink: "https://github.com/Pradeep22222/expenses-tracker-as-monolothic",
    liveLink: "https://expensestrackerapplication.herokuapp.com/",
    content:
      "Multi-user web application that lets users keep track of their expenses.",
  },
  {
    id: 3,
    image: nottodo1,
    transitionImage: nottodo2,
    title: "Not to do list || MERN ",
    codeLink: "https://github.com/Pradeep22222/not-to-do-list-ready-to-host",
    liveLink: "https://pradeep-not-to-do.herokuapp.com/",
    content:
      "Single-user web application that lets users keep a record of their activities and categorises them.",
  },
  {
    id: 4,
    image: countrydetails1,
    transitionImage: countrydetails2,
    title: "Country Info || React ",
    codeLink: "https://github.com/Pradeep22222/Country-Details-mine",
    liveLink: "https://country-details-mine.vercel.app/",
    content:
      "The application makes an API call to the public API and  provides details on capital city, population, area and language about the country we look for.",
  },
  {
    id: 5,
    image: movie1,
    transitionImage: movie2,
    title: "Movie Album || React",
    codeLink: "https://github.com/Pradeep22222/Movie-API-on-react",
    liveLink: "https://movie-api-on-react.vercel.app/",
    content:
      "The application makes an API call to fetch information about the movie we searched for from the public API. The movie received can be categorised into different categories in the front end and two different forms of views, “grid” and “list” is applied.",
  },
  {
    id: 6,
    image: prankcalculator1,
    transitionImage: prankcalculator2,
    title: "Prank-Calculator || React",
    codeLink:
      "https://github.com/Pradeep22222/react-prank-calculator-with-prank",
    liveLink: "https://react-prank-calculator-with-prank.vercel.app/",
    content:
      "It is a calculator build on react with a little prank featured applied.",
  },
  {
    id: 7,
    image: downsyndrome1,
    transitionImage: downsyndrome2,
    title: "Down Syndrom Queensland ||  HTML, CSS(SCSS) and JS",
    codeLink: "https://github.com/Pradeep22222/Capstone-Project",
    liveLink: "https://pradeep22222.github.io/Capstone-Project/index.html",
    content: `Was developed for a real client, “Down Syndrome Queensland” as a part of a curriculum project where the main feature applied was a calendar booking system. `,
  },

  {
    id: 8,
    image: oasis1,
    transitionImage: oasis2,
    title: "Oasis Fancy Collection || HTML and CSS (SCSS)",
    codeLink: "https://github.com/Pradeep22222/Oasis-fancy-collection",
    liveLink: "https://pradeep22222.github.io/Oasis-fancy-collection/",
    content:
      "A simple E-Commerce website experience, built with just HTML and CSS (SCSS) as a part of curriculum.",
  },
];
