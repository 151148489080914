import React from "react";
import { Parallax } from "react-parallax";
import {
  ParallaxContainer,
  ParallaxText,
} from "../../style/parallax/parallaxStyle";


const ParallaxComponent = () => {
  return (
    <Parallax
      bgImage="https://res.cloudinary.com/dt5eiyxyc/image/upload/v1691718915/quote-background_oaq0ty.jpg"
      strength={-200}
      style={{ width: "100%" }}
    >
      <ParallaxContainer>
        <ParallaxText>
          "Success is achieved with a series of risks, compromisations and
          sacrifications"
        </ParallaxText>
      </ParallaxContainer>
    </Parallax>
  );
};

export default ParallaxComponent;
