import React from "react";
import {
  AboutContent,
  AboutDetailsContainer,
  AboutImage,
  AboutImageContainer,
  Resume,
  // ResumeLink,
  StyledAbout,
} from "../../style/about/AboutStyles";
// import resume from "../../assets/PradeepKumarCV.pdf";
// import downloadIcon from "../../assets/icons/download-icon.svg";
import {
  SectionHeading,
  SectionTitle,
} from "../../style/projects/sectionHeading";
import { StyledParagraph } from "../../style/projects/TypographyStyle";

export const About = () => {
  return (
    <StyledAbout id="about">
      <SectionHeading dark="true" mb="3rem">
        <SectionTitle dark="true" number="02">
          About Me
        </SectionTitle>
      </SectionHeading>
      <AboutContent>
        <AboutImageContainer className="about_image-container">
          <AboutImage
            className="about_image"
            src="https://res.cloudinary.com/dt5eiyxyc/image/upload/v1691718642/288415039_850778515898656_4702617939993874973_n_2_upnozn.png"
          />
        </AboutImageContainer>
        <AboutDetailsContainer>
          <StyledParagraph>
            I am Pradeep Kumar Dhital, an experienced full stack developer and an  IT graduate  based in Sydney, Australia.
          </StyledParagraph>
          <StyledParagraph>
            My passion lies in crafting responsive websites and web applications from the ground up. To achieve this, I utilize diverse set of technologies, tools and programming languages including HTML, CSS, SCSS, JavaScript, React, Redux, Node , MongoDB, npm packages, Git, GitHub and VS Code.
          </StyledParagraph>
          <StyledParagraph>
            Programming in not just a profession for me; it's a genuine passion that drives me to embrace continuous learning, eagerly seeking out new knowledge and skills everyday.
          </StyledParagraph>
          <Resume>
            {/* <ResumeLink
              className=" button_resume"
              // href={resume}
              icon={downloadIcon}
              download
            >
              Download Resume
            </ResumeLink> */}
          </Resume>
        </AboutDetailsContainer>
      </AboutContent>
    </StyledAbout>
  );
};
