import React from "react";
import {
  TechnologiesContainer,
  TechnologiesHeader,
} from "../../style/technologies/technologyStyle";
import rest from "../../assets/icons/restapi.png";
import adobe from "../../assets/icons/adobe.png";
import mongo from "../../assets/icons/mongodb.png";
import redux from "../../assets/icons/redux.png";
import typescript from "../../assets/icons/typescript.png";
import nextjs from "../../assets/icons/nextjs.png";
import postgres from "../../assets/icons/postgres.png";

const Technologies = () => {
  return (
    <TechnologiesContainer id="technologies">
      <TechnologiesHeader className="mb-5">
        Technologies I have been using
      </TechnologiesHeader>

      <div className="icons fw-bolder d-flex flex-wrap justify-content-around gap-3">
        <div className="skill_div" title="HTML5">
          <i className="fa-brands fa-html5 text-warning"></i>
          <span>HTML5</span>
        </div>
        <div className="skill_div" title="CSS3">
          <i className="fa-brands fa-css3-alt text-primary"></i>
          <span>CSS3</span>
        </div>
        <div className="skill_div" title="JavaScript/ES6">
          <i className="fa-brands fa-js-square text-warning"></i>
          <span>JavaScript</span>
        </div>
        <div className="skill_div" title="TypeScript">
          <img src={typescript} alt="" className="skills_pic typescript_pic" />
          <span>TypeScript</span>
        </div>
        <div className="skill_div" title="Bootstrap5">
          <i className="fa-brands fa-bootstrap text-primary"></i>
          <span>Bootstrap</span>
        </div>
        <div className="skill_div" title="SCSS">
          <i className="fa-brands fa-sass  color_pink"></i>
          <span>SCSS</span>
        </div>
        <div className="skill_div" title="ReactJs">
          <i className="fa-brands fa-react text-info"></i>
          <span>ReactJs</span>
        </div>
        <div className="skill_div" title="nextjs">
          <img src={nextjs} alt="" className="skills_pic next_pic" />
          <span>NextJs</span>
        </div>
        <div className="skill_div" title="Redux">
          <img src={redux} alt="" className="skills_pic" />
          <span>Redux</span>
        </div>
        <div className="skill_div" title="NodeJs">
          <i className="fa-brands fa-node text-success skill_div_item"></i>
          <span>NodeJs</span>
        </div>
        <div className="skill_div" title="Restful APIs">
          <img src={rest} alt="" className="skills_pic rest_pic" />
          <span>REST APIs</span>
        </div>
        <div className="skill_div" title="MongoDB">
          <img src={mongo} alt="" className="skills_pic mongo_pic" />
          <span>MongoDB</span>
        </div>
        <div className="skill_div" title="Postgres SQL">
          <img src={postgres} alt="" className="skills_pic postgres_pic" />
          <span>Postgres SQL</span>
        </div>
        <div className="skill_div" title="GitHub">
          <i className="fa-brands fa-github-square"></i>
          <span>GitHub</span>
        </div>
        <div className="skill_div" title="Git">
          <i className="fa-brands fa-git-alt color_git"></i>
          <span>Git</span>
        </div>
        <div className="skill_div" title="NPM">
          <i className="fa-brands fa-npm color_npm"></i>
          <span>NPM</span>
        </div>
        <div className="skill_div" title="Adobe Photoshop">
          <img src={adobe} alt="" className="skills_pic" />
          <span>Photoshop</span>
        </div>
      </div>
    </TechnologiesContainer>
  );
};

export default Technologies;
