export const theme = {
  fonts: {
    montserrat: "'Montserrat', sans-serif",
    cardo: "'Cardo', serif",
  },
  backgroundColor: {
    dark: "#0d0e0e",
    light: "#ffffff",
    orange: "#e64e04",
    gray: "#c4c4c4",
    black: "#000000",
    darkGray: "#111",
    purple: "#8000ff",
    white: "#ffffff",
    lightPurple: "rgb(207, 89, 230)",
    lightBlue: "#6BC5F8",
    scarlet: "#B85042",
    Light_teal: "#A7BEAE",
    Light_olive: "#E7E8D1",
  },
  color: {
    dark: "#000000",
    darkGray: "#262637",
    lightGray: "#aaa",
    light: "#fff",
    secondaryLight: "#f1f1f1",
    tertiaryLight: "#d3d3d3",
    link: "#4353ff",
    lightPurple: "rgb(207, 89, 230)",
    lightBlue: "#6BC5F8",
    white: "#ffffff",
    gray: "#c4c4c4",
    black: "#000000",
  },
  CTA: {
    primary: "#e64e04",
    secondary: "#4353ff",
  },
  breakpoints: {
    aboutfirst: "109rem",
    aboutsecond: "94.7rem",
    aboutthird:"75.4rem",
    lg: "64rem",
    md: "58.125rem",
    sm: "48rem",
    xs: "31.25rem",
    // mobile: "only screen and (max-width: 480px)",
    // tablet: "only screen and (max-width: 768px)",
    // desktop: "only screen and (max-width: 1024px)",
    // largeDesktop: "only screen and (max-width: 1440px)",
    // sm: "only screen and (max-width: 480px)",
    // md: "only screen and (max-width: 768px)",
    // lg: "only screen and (max-width: 1024px)",
    // xl: "only screen and (max-width: 1440px)",
  },
};
